/* Container Styling */
.sharelink-container {
    padding: 20px;
    text-align: center;
    background-color: #fef9ed;
    color: #4c285e;
    font-family: 'Poppins';
  }
  
  /* Title Styling */
  .sharelink-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #5e17eb;
  }
  
  /* Link Input Container */
  .link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  /* Link Input Styling */
  .link-input {
    width: 70%;
    max-width: 400px;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #d3b4d0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #4c285e;
    text-align: center;
    transition: border-color 0.3s ease;
  }
  
  .link-input:focus {
    border-color: #5e17eb;
    outline: none;
  }
  
  /* Copy Button Styling */
  .copy-button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #5e17eb;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .copy-button:hover {
    background-color: #4c285e;
    transform: scale(1.05);
  }
  
  .copy-button:active {
    transform: scale(1);
  }
  
  /* Error Text */
  .error-text {
    font-size: 1.2rem;
    color: red;
    margin-top: 20px;
  }
  
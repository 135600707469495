.reviews-container {
    background-color: #8b6a85; /* Adjust to match your background */
    padding: 60px 20px;
    color: #ffffff;
    text-align: center;
    font-family: 'Poppins';
  }
  
  .reviews-title {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 40px;
  }
  
  .reviews-content {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    gap: 30px;
    margin: 0 auto; /* Ensure the content stays in the center */
    max-width: 1200px; /* Limit the width of the content */
  }
  
  .decorative-image {
    width: 150px; /* Adjust size as needed */
    height: 200px;
  }
  
  .reviews-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align reviews to the left */
    gap: 20px;
  }
  
  .review-item {
    background-color: #fdfaf3; /* Card background color */
    border-radius: 16px;
    padding: 20px;
    text-align: left;
    width: 400px; /* Set a fixed width for the reviews */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .review-name {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
    font-family: 'Poppins'; /* Decorative font */
  }
  
  .review-text {
    font-size: 0.95rem;
    color: #555;
    line-height: 1.5;
  }
  
.why-nuqoot-container {
    background: #946b87; /* A purple-ish background similar to provided image */
    padding: 60px 20px;
    text-align: center;
    font-family: 'Poppins'; /* Adjust font as needed */
    color: #fff; /* #fef9ed text for the title */
    box-sizing: border-box;
    padding-bottom: 100px;
  }
  
  .why-nuqoot-title {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 40px;
    color: #fff; /* #fef9ed or a light cream if needed */
    position: relative;
  }
  
  .why-nuqoot-title::after {
    content: "";
    display: block;
    width: 80px;
    height: 4px;
    background: #fff;
    margin: 10px auto 0;
    border-radius: 2px;
  }
  
  /* Card Container */
  .why-nuqoot-cards {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  /* Individual Card */
  .why-nuqoot-card {
    background: transparent; /* Cream background similar to shown image */
    color: #4c285e; /* Darker text color for contrast */
    border-radius: 20px; /* Rounded corners similar to provided image */
    padding: 20px;
    flex: 1 1 250px; /* Allow responsive shrink/grow */
    max-width: 300px; /* A fixed max width for cards */
    text-align: center;
    box-sizing: border-box;
  }
  
  .card-heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: #4c285e; /* Dark text for heading */
    margin-bottom: 15px;
    font-family: 'Poppins'; /* Adjust font as needed */
  }
  
  .card-text {
    font-size: 1rem;
    font-weight: 400;
    color: #4c285e;
    margin-bottom: 20px;
    line-height: 1.4;
    font-family: 'Poppins'; /* Adjust font as needed */
  }
  
  /* Card Images */
  .why-nuqoot-card card-image1{
    width: 200px;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 800px) {
    .why-nuqoot-container {
      background: #946b87; /* A purple-ish background similar to provided image */
      text-align: center;
      font-family: 'Poppins'; /* Adjust font as needed */
      color: #fff; /* #fef9ed text for the title */
      box-sizing: border-box;
      padding-top:10px;
      padding-bottom: 10px;
    }

    .why-nuqoot-cards {
      gap: 20px;
    }
  
    .why-nuqoot-title {
      font-size: 2.5rem;
    }
  }
  
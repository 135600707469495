@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.benefits-container {
    text-align: center;
    background-color: #946b87; /* Adjust to match your design */
    padding: 60px 20px;
    font-family: 'Poppins';
    color: #ffffff;
  }
  
  .benefits-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .benefits-grid {
    display: flex;
    justify-content: center; /* Center the grid */
    align-items: center;
    gap: 30px;
  }
  
  .benefit-card {
    background-color: transparent; /* Card background color */
    padding: 20px;
    border-radius: 16px;
    width: 280px;
    text-align: center;
  }
  
  .benefit-icon {
    width: 60px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  .benefit-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fef9ed;
  }
  
  .benefit-description {
    font-size: 0.95rem;
    color: #fef9ed;
    line-height: 1.5;
  }
  

  @media (max-width: 700px) {
    .benefits-grid{
      flex-direction: column;
      align-items: center;
      text-align: center;
    }


  }
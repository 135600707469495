.home-page-containe{
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-x: hidden;
  }
  
  .home-page {
    position: relative;
  }
  
  .dashboard-container {
    position: relative;
    z-index: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
    height:auto;
  }
  
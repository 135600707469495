html, body {
  height: 100%;
  margin: 0;
}

.recipient-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;      
  justify-content: center;  
  background-color: #fef9ed;
  color: #4c285e;
  padding: 20px;
  text-align: center;
  animation: fadeIn 1s ease; /* Fade-in animation on load */
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #000;
}

/* Card Flip Container */
.card-flip-container {
  display: inline-block;
  position: relative;
  perspective: 1000px;
  cursor: pointer;
  margin-bottom: 20px;
  animation: bounceIn 1s ease; /* A subtle bounce when the card appears */
}

@keyframes bounceIn {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.card-flip {
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card-flip.flipped {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: relative;
  backface-visibility: hidden;
  display: block;
}

.card-size-wrapper {
  display: inline-block;
  position: relative;
}

.card-back {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

.card-image1234 {
  display: block;
  height: 300px;
  width: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  transition: transform 0.4s;
}

.card-image1234:hover {
  transform: scale(1.03);
}

/* Message Overlay fully covers the card and centers the message */
.message-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Use flex to center content both vertically and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  animation: fadeInMessage 0.8s 1.8s forwards;
  font-style: italic;
  pointer-events: none; /* Ensures clicking card still works if overlay is transparent */
}

@keyframes fadeInMessage {
  from { opacity: 0; }
  to { opacity: 1; }
}

.message-text {
  font-size: 1.5rem;
  color: #4c285e;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 80%; 
}

.accept-button, .replay-button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  background-color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.accept-button:hover, .replay-button:hover {
  background-color: #4c285e;
  transform: scale(1.05);
}

/* Replay button uses a different base color */
.replay-button {
  background-color: #8C6381;
  margin-left: 10px;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .title {
    font-size: 1.5rem;
  }

  .message-text {
    font-size: 1rem;
  }

  .accept-button, .replay-button {
    width: auto;
    font-size: 0.9rem;
  }
}

.card-selector-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    font-family: 'Poppins';
    justify-content: center;
  }
  
  .card-item {
    width: 150px;
    height: 200px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .card-item:hover {
    transform: scale(1.05);
  }
  
  .card-item.selected {
    border: 2px solid #4CAF50; /* Highlight color */
  }
  
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.features-container {
    text-align: center;
    background-color: #fef9ed; /* Adjust to match your design */
    padding-top:140px;
    padding-bottom: 140px;
  }
  
  .features-title123 {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 30px;
    margin-bottom: 100px;
    letter-spacing: 4px;
    color: #333;
  }
  .features-grid {
    display: flex;
    justify-content: center; /* Center the items */
    align-items: flex-start; /* Align items to the top for consistency */
    gap: 100px; /* Space between items */
  }
  
  .feature-item {
    flex: 0 1 300px; /* Ensure items don't stretch too much */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align content to the center */
  }
  
  .feature-icon {
    width: 200px; /* Fixed width for consistency */
    height: 200px; /* Fixed height for consistency */
    object-fit: contain; /* Ensures the image fits within bounds */
    margin-bottom: 0px;
    margin-top: 30px
  }
  
  .feature-item h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 50px; /* Ensure no unnecessary margin */
    margin-bottom: 20px; /* Keep spacing consistent */
    color: #333;
  }
  
  .feature-item p {
    font-size: 1.4rem;
    color: #555;
    line-height: 1.5;
    margin: 0; /* Remove unnecessary margins */
  }
  
  @media (max-width: 768px) {
    .features-grid {
      flex-direction: column; /* Stack items vertically */
      gap: 20px; /* Reduce spacing for smaller screens */
    }
  
    .feature-item {
      flex: 1 1 auto; /* Allow items to resize as needed */
      text-align: center;
      padding: 0px; /* Add padding for better spacing */
    }
  
    .feature-icon {
      width: 100px; /* Smaller size for mobile */
      height: 100px;
      margin-bottom: 0px; /* Adjust spacing for mobile */
    }

    .feature-item h3 {
      font-size: 1.4rem; /* Smaller font for better fit */
      margin-bottom: 15px; /* Adjust spacing */
      margin-top: 20px;
    }
  
    .feature-item p {
      font-size: 1rem; /* Adjust font size for readability */
      line-height: 1.4; /* Slightly reduce line height */
      padding: 10px;
    }
  
    .features-container {
      padding-top: 80px; /* Reduce padding for mobile */
      padding-bottom: 80px;
    }
  
    .features-title123 {
      font-size: 2rem; /* Reduce title size */
      font-weight: bold;
      margin-bottom: 50px; /* Reduce spacing below the title */
      padding: 10px;
    }
  }
  
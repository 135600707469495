.send-money-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins';
  padding: 20px;
  background-color: #fef9ed; /* Adjust to match the background color */
  flex-wrap: wrap;
}

.text-section1 {
  max-width: 50%;
  flex: 1;
}

.text-section1 h1 {
  font-size: 4rem;
  font-weight: bold;
  color: #333; /* Darker color for contrast */
  margin-bottom: 20px;
}

.text-section1 .highlight {
  color: #6f4c7a; /* Adjust to match your theme */
}

.text-section1 p {
  font-size: 1.4rem;
  color: #555;
}

.image-section1 {
  max-width: 45%; /* Adjust as needed for responsiveness */
  flex: 1;
}

.phone-image {
  width: 100%;
  height: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .send-money-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-section1,
  .image-section1 {
    max-width: 100%;
  }

  .text-section1 h1 {
    font-size: 2rem;
  }

  .text-section1 p {
    font-size: 1.2rem;
  }
}

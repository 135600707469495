@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.faq-container {
    text-align: center;
    background-color: #946b87; /* Adjust to match your background */
    padding: 60px 20px;
    font-family: 'Poppins';
    color: #ffffff;
  }
  
  .faq-title {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 40px;
  }
  
  .faq-grid {
    display: flex;
    justify-content: center; /* Center the items */
    align-items: flex-start;
    gap: 40px; /* Space between items */
  }
  
  .faq-item {
    background-color: transparent; /* Card background */
    border-radius: 16px;
    padding: 20px;
    text-align: center;
    width: 300px;
  }
  
  .faq-icon {
    width: 50px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  .faq-question {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fef9ed;
  }
  
  .faq-answer {
    font-size: 1.1rem;
    color: #fef9ed;
    line-height: 1.5;
  }


  @media (max-width: 700px) {
    .faq-grid {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  
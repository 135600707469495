@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.footer-container {
  background: #946b87; /* Purple background */
  position: relative;
  padding: 40px 50px;
  box-sizing: border-box;
  font-family: 'Poppins';
  color: #ffffff;
}

.footer-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1400px;
  margin: 0 auto;
}

.footer-left .footer-logo {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  color: black; /* 'nuqoot' in black as per screenshot */
}

.footer-middle {
  margin-left: 150px;
}

.footer-middle .footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-middle .footer-links p {
  margin-bottom: 10px;
  color: #f1f1f1;
  font-size: 1rem;
  cursor: pointer;
}

.footer-middle .footer-links p:hover {
  text-decoration: underline;
}

.footer-right {
  margin-left: auto;
  text-align: right;
}

.footer-connect {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #e3e3e3;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-bottom: 20px; /* Add spacing before the new Instagram icon */
}

.social-icons i {
  font-size: 1.2rem;
  color: #dcdcdc;
  cursor: pointer;
  transition: color 0.3s;
}

.social-icons i:hover {
  color: #ffffff;
}

/* New Instagram icon styling */
.instagram-link {
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
}

.instagram-icon {
  width: 24px;
  height: auto;
}

.footer-bottom {
  margin-top: 50px;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 50px;
}

.logo-image {
  width: auto;
  height: 30px;
  display: block;
  margin: 0 auto;
}

/* Responsive Adjustments */
@media (max-width: 800px) {
  .footer-left {
    margin-bottom: 50px;
  }
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-middle {
    margin-left: 0;
  }

  .footer-right {
    margin-top: 20px;
    text-align: center;
    margin-left: 0; 
  }

  .footer-bottom {
    margin-top: 30px;
    text-align: center;
    justify-content: center;
  }
}

/* General Container Styling */
.send-nuqoot-container {
  padding: 0px;
  text-align: center;
  background-color: #fef9ed;
  font-family: 'Poppins';
  color: #4c285e;
}

/* Title Styling */
.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 40px;
}

/* Event Section */
.event-section {
  margin-bottom: 30px;
}

.event-title {
  font-size: 2rem;
  margin-bottom: 50px;
  color: black;
}

/* Card Grid Styling - horizontal scroll */
.card-grid {
  display: flex;
  gap: 10px; /* Reduced gap for cards to be closer */
  overflow-x: auto;
  padding-bottom: 10px;
  scrollbar-width: thin; /* Firefox */
}

/* Customize scrollbar for WebKit browsers */
.card-grid::-webkit-scrollbar {
  height: 6px;
}

.card-grid::-webkit-scrollbar-thumb {
  background: #5e17eb;
  border-radius: 3px;
}

/* Individual Card Styling */
.card-item {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  flex: 0 0 auto; /* Prevents shrinking, making sure each card maintains size */
  width: auto; /* Larger width */
  height: 300px; /* Larger height */
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.card-item:hover {
  transform: scale(1.0) !important;
}

.card-item.selected {
  border-color: #454446;
}

.card-image {
  width: auto !important;
  height: 300px !important;
  object-fit: cover;
}

/* Next Button Styling */
.next-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Poppins';
  color: #fff;
  background-color: #67666a !important;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 60px;
  transition: background-color 0.3s ease;
}

.next-button:hover {
  background-color: #79757b;
}

@media (max-width: 600px) {

  .card-image {
    width: auto !important;
    height: 200px !important;
    object-fit: cover;
  }
}
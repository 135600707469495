/* General Container Styling */
.send-nuqoot-container1 {
    padding: 0px;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    background-color: #fef9ed;
    font-family: 'Poppins';
    color: #4c285e;
  }
  
  /* Title Styling */
  .title1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  /* Event Section */
  .event-section1 {
    margin-bottom: 30px;
  }
  
  .event-title1 {
    font-size: 2rem;
    margin-bottom: 50px;
    color: black;
  }
  
  /* Card Grid Styling - now a 3-column grid */
  .card-grid1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
    gap: 20px; /* space between cards */
    padding: 0 20px; /* optional horizontal padding */
    margin-bottom: 40px; /* space after the grid */
  }
  
  /* Individual Card Styling (unchanged dimensions) */
  .card-item1 {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
    overflow: hidden;
    flex: 0 0 auto;
    width: auto; /* no fixed width */
    height: 300px; /* original height */
    transition: transform 0.3s ease, border-color 0.3s ease;
  }
  
  .card-item1:hover {
    transform: scale(1.0) !important;
  }
  
  .card-item1.selected {
    border-color: #454446;
  }
  
  .card-image1 {
    width: auto !important;
    height: 300px !important;
    object-fit: cover;
  }
  
  /* Next Button Styling */
  .next-button1 {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Poppins';
    color: #fff;
    background-color: #67666a !important;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 60px;
    transition: background-color 0.3s ease;
  }
  
  .next-button1:hover {
    background-color: #79757b;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 600px) {
    .card-image1 {
      width: auto !important;
      height: 200px !important;
      object-fit: cover;
    }
  
    /* Adjust the grid to fit on smaller screens */
    .card-grid1 {
      grid-template-columns: 1fr; /* single column on very small screens */
    }
  }
  
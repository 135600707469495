/* Global reset for sizing */
* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling due to overflow */
}

/* General Container Styling */
.payment-container3 {
  padding: 20px;
  background-color: #fef9ed;
  color: #4c285e;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

/* Title Styling */
.title3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.flip-instruction3 {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #4c285e;
  font-style: italic;
}

/* Card Flip Container */
.card-flip-container3 {
  display: inline-block;
  position: relative;
  perspective: 1000px;
  cursor: pointer;
  margin-bottom: 20px;
}

.card-flip3 {
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card-flip3.flipped {
  transform: rotateY(180deg);
}

.card-front3,
.card-back3 {
  position: relative;
  backface-visibility: hidden;
  display: block;
}

.card-size-wrapper3 {
  display: inline-block;
  position: relative;
}

.card-back3 {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

.card-image12343 {
  display: block;
  height: 300px;
  width: auto;
  border-radius: 8px;
}

.card-name3 {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2rem;
  font-weight: bold;
  color: #5e17eb;
  background: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
}

.message-overlay3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: #4c285e;
  text-align: center;
  white-space: pre-wrap;
  width: 80%;
  background-color: transparent;
  word-wrap: break-word;
  font-style: italic;
  padding: 10px;
  border-radius: 5px;
}

/* Amount Input Container */
.amount-input-container3 {
  position: relative;
  width: 400px;
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
}

.amount-input3 {
  width: 100%;
  font-size: 1rem;
  padding: 10px 10px 10px 35px; /* space for the $ sign */
  border: 2px solid #d3b4d0;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.amount-input3:focus {
  border-color: #5e17eb;
  outline: none;
}

.amount-currency3 {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  font-weight: 800;
  color: #000000;
  pointer-events: none;
  z-index: 2; 
}

/* Card Element Container */
.card-element-container3 {
  width: 400px;
  max-width: 400px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

/* Pay Button */
.pay-button3 {
  width: 400px;
  max-width: 400px;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #8e618a;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pay-button3:hover {
  background-color: #4c285e;
}

/* Fees Summary Section */
.fees-summary-container3 {
  width: 400px;
  max-width: 400px;
  margin: 20px auto;
  text-align: left;
  background-color: #ffffff;
  border: 2px solid #d3b4d0;
  border-radius: 8px;
  padding: 20px;
  color: #4c285e;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
}

.fees-summary-title3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.fees-line-item3 {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 10px;
}

.fees-divider3 {
  border: none;
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}

.total-line3 {
  font-size: 1.1rem;
  font-weight: bold;
}

.fee-explanation3 {
  font-size: 0.9rem;
  color: #4c285e;
  margin: 10px auto 20px auto;
  width: 400px;
  max-width: 400px;
  text-align: left;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .card-image12343 {
    height: 200px !important;
  }

  .amount-input-container3,
  .card-element-container3,
  .pay-button3,
  .fees-summary-container3,
  .fee-explanation3 {
    width: 90% !important;
    max-width: none;
    margin: 20px auto;
  }

  .amount-input3, .amount-currency3 {
    font-size: 0.7rem !important;
  }

  .message-overlay3 {
    font-size: 0.8rem !important;
  }

  .flip-instruction3 {
    font-size: 0.7rem;
  }

  .fees-line-item3 {
    font-size: 0.8rem;
  }

  .fee-explanation3 {
    font-size: 0.8rem;
  }
}

/* Container Styling */
.accept-money-container {
  padding: 20px;
  text-align: center;
  background-color: #fef9ed;
  color: #4c285e;
  font-family: 'Poppins';
}

/* Title Styling */
.accept-money-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000000;
}

/* Form Styling */
.accept-money-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Bank Details Container */
.bank-details-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  background-color: #fff;
  border: 2px solid #d3b4d0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.bank-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background-color: #f6f7ff;
  border: none;
  border-radius: 8px;
  outline: none;
  box-sizing: border-box;
}

.bank-input:focus {
  border: 2px solid #5e17eb;
  background-color: #fff;
}

/* Button Styling */
.accept-money-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.accept-money-button:hover {
  background-color: #4c285e;
  transform: scale(1.05);
}

.accept-money-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Success Message */
.success-message {
  font-size: 1.2rem;
  color: #4c285e;
  margin-top: 20px;
  font-weight: bold;
}

/* Error Message */
.error-message {
  font-size: 1rem;
  color: red;
  margin-top: 15px;
  font-weight: bold;
}

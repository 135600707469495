.customize-card-container1 {
  padding: 20px;
  text-align: center;
  background-color: #fef9ed;
  color: #4c285e;
  font-family: 'Poppins', sans-serif;
}

.customizedcontainer1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Desktop view: show both cards side by side */
.card-preview-desktop1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Mobile view: show only one card */
.card-preview-mobile1 {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; /* Indicate tap for flipping */
}

.flip-instruction1 {
  font-size: 1rem;
  margin-top: 10px;
  color: #4c285e;
  opacity: 0.8;
}

.card-image-container1 {
  position: relative;
  display: inline-block;
}

.card-image121 {
  width: auto;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.message-overlay1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #4c285e;
  text-align: center;
  white-space: pre-wrap;
  width: 80%;
  word-wrap: break-word;
}

/* Message Input Styling */
.message-input121 {
  width: 90%;
  max-width: 600px;
  height: 100px;
  padding: 10px;
  margin: 20px 0;
  font-size: 1rem;
  background-color: transparent;
  border: 2px solid #d3b4d0;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  resize: none;
}

.message-input121:focus {
  border-color: #5e17eb;
  outline: none;
}

/* Recipient Name Input Styling */
.recipient-name-input {
  width: 90%;
  max-width: 600px;
  height: 40px;
  padding: 10px;
  margin: 20px 0;
  font-size: 1rem;
  background-color: transparent;
  border: 2px solid #d3b4d0;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
}

.recipient-name-input:focus {
  border-color: #5e17eb;
  outline: none;
}

/* Next Button Styling */
.next-button1 {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #5e17eb;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.next-button1:hover {
  background-color: #4c285e;
}

/* Responsive Adjustments */
@media (max-width: 890px) {
  /* On mobile, hide the desktop preview and only show mobile preview */
  .card-preview-desktop1 {
    display: none;
  }
  
  .card-preview-mobile1 {
    display: flex;
  }
}

@media (min-width: 891px) {
  /* On desktop, hide the mobile preview */
  .card-preview-mobile1 {
    display: none;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.bar {
  width: 100%;
  background-color: #fef9ed;
  color: #5e17eb;
  position: sticky;
  top: 0;
  font-family: 'Poppins', sans-serif;
  z-index: 1000;
}

.navbar {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  height: 90px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative; /* for absolutely positioning hamburger icon */
}

.logo {
  cursor: pointer;
}

.logo-image {
  height: 60px;
  width: auto;
}

/* Center Links (Desktop) */
.links-center {
  display: flex;
  justify-content: center;
}

.links {
  display: flex;
  align-items: center;
  gap: 30px;
  color: black;
  font-weight: 450;
  font-size: 1.2rem;
  cursor: pointer;
}

.links p:hover {
  color: #7b7b7b;
}

/* Dropdown (Desktop) */
.dropdown {
  position: relative;
}

.dropdown-button {
  cursor: pointer;
  font-size: 1.2rem;
  user-select: none;
}

/* Start hidden */
.dropdown-menu {
  display: none; 
  background-color: #fef9ed;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
  position: absolute;
  top: 100%;
  left: 0;
  padding: 20px;
  gap: 50px;
  text-align: left;
  min-width: 400px; /* Adjust width as needed */
  flex-wrap: wrap; /* Ensures the columns stay side-by-side if needed */
}

.dropdown:hover .dropdown-menu {
  display: flex; /* Only visible on hover */
}

.dropdown-column {
  display: flex;
  flex-direction: column;
}

.dropdown-column h4 {
  margin: 0 0 10px 0;
  font-weight: 600;
  color: black;
  font-size: 1.1rem;
}

.dropdown-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-column li {
  margin-bottom: 8px;
  font-size: 1rem;
  cursor: pointer;
  color: #333;
  position: relative;
}

.dropdown-column li:hover {
  color: #7b7b7b;
  cursor: pointer;
}

/* Right Section (Desktop) */
.right-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Send Nuqoot Button (Desktop) */
.send-nuqoot-btn {
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
}

.send-nuqoot-btn:hover {
  background-color: #b295b8;
}

/* Hamburger Icon */
.hamburger-icon {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: black;
  position: absolute;
  right: 20px;
  top: 17px;
}

.hamburger-icon.open {
  color: #b295b8;
}

/* Mobile Dropdown */
.mobile-dropdown {
  display: none;
}

/* Responsive Styles */
@media (max-width: 800px) {
  .links-center,
  .right-section {
    display: none;
  }

  .hamburger-icon {
    display: block;
  }

  .mobile-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fef9ed;
    width: 100%;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    position: absolute;
    top: 90px;
    left: 0;
    gap: 20px;
  }

  .mobile-dropdown p {
    margin: 0;
    font-size: 1.2rem;
    cursor: pointer;
    color: black;
  }

  .mobile-dropdown p:hover {
    color: #7b7b7b;
  }

  .send-nuqoot-btn-mobile {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
  }

  .send-nuqoot-btn-mobile:hover {
    background-color: #b295b8;
  }

  /* Hide the desktop dropdown menu entirely on mobile */
  .dropdown-menu {
    display: none !important;
  }
}

/* General Container Styling */
.delivery-options-container {
  padding: 20px;
  text-align: center;
  background-color: #fef9ed;
  font-family: 'Poppins';
  color: #4c285e;
}

/* Title Styling */
.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #4c285e;
}

/* Optional: A container for form elements to form a grid */
.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 20px;
  margin-top: 20px; /* Add some space between title and form */
}

/* Select Dropdown Styling */
.delivery-select {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #d3b4d0;
  border-radius: 8px;
  background-color: #fff;
  color: #4c285e;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.delivery-select:focus {
  border-color: #4c285e;
  outline: none;
}

/* Input Field Styling */
.recipient-input {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #d3b4d0;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.recipient-input:focus {
  border-color: #4c285e;
  outline: none;
}

/* Send Button Styling */
.send-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #4c285e;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #4c285e;
}

/* Error Message Styling (if any) */
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 20px;
}

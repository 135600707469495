@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.about-section {
  padding: 20px;
  background-color: #946b87; /* Adjust to match the background color */
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: 'Poppins';
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Ensure wrapping for responsiveness */
}

.text-section {
  max-width: 45%; /* Limit the text section width */
  flex: 1;
}

.text-section h1 {
  font-size: 5rem;
  font-weight: bold;
  color: #ffffff; /* Light color for contrast */
  margin-bottom: 20px;
}

.text-section .highlight {
  color: #ffe6f0; /* Adjust to a theme-matching color */
}

.text-section p {
  font-size: 1.4rem;
  color: #fff; /* Light color for text */
  line-height: 1.6;
}

.image-section {
  max-width: 45%; /* Limit the image section width */
  flex: 1;
}

.about-image {
  width: 100%; /* Ensure the image is responsive */
  height: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-section {
    padding: 20px;
    background-color: #946b87; /* Adjust to match the background color */
    padding-top: 50px;
    padding-bottom: 10px;
    font-family: 'Poppins';
  }
  
  .about-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
  }

  .text-section,
  .image-section {
    max-width: 100%;
  }

  .text-section h1 {
    font-size: 2.5rem; /* Adjust for smaller screens */
  }

  .text-section p {
    font-size: 1.2rem;
  }

  .about-image {
    height: 250px;
    width: auto;
  }
}

/* Center the login container on the page */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  margin: auto;
  padding: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 100px;
  gap: 15px;
}

.title {
  align-items: center;
  text-align: center;
  color: black;
  margin: 1 auto;
  font-size: 1.9rem;
  font-weight: 700;
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  background-color: #f6f7ff;
  height: 40px;
}

.login-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 100;
  margin-bottom: 15px;
  margin-top: 25px;
  font-family: 'Poppins', sans-serif;
}

.login-button:hover {
  background-color: grey;
}

.login-links {
  text-align: center;
  margin-top: 45px;
  font-size: 0.9rem;
}

.signup-link {
  color: purple;
  text-decoration: none;
}

.signup-link:hover {
  text-decoration: underline;
}

.error-text {
  color: red;
  margin-top: 10px;
  font-size: 0.9rem;
}

.divider {
  width: 100%;
  margin: 20px 0;
  border: 0.5px solid #ccc;
}

/* Social button styles (similar to BusinessSignUp) */
.social-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: left;
  background-color: #f6f7ff;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.social-button:hover {
  background-color: #b0b1b7;
}

.social-logo {
  width: 20px;
  height: auto;
  margin-right: 20px;
  margin-bottom: -3px;
  margin-left: 10px;
}

.google-button {
  width: 100%;
  height: 50px;
  background-color: #b1b1b1;
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: 'Poppins' !important;
  margin-bottom: 15px;
}

.google-button:hover {
  background-color: #b0b1b7;
}
